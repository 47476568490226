import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./CoursesContainer.module.css";
import CourseCard from "components/containers/CoursesContainer/CourseCard/CourseCard";
import { Spin, message } from "antd";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getUserCustomProperty } from "utils/auth0Utils";
import {
  makeFetchRequest,
  useAccessTokenRequestHeaderConfig,
} from "utils/requestUtils";

const CoursesContainer = () => {
  const [courses, setCourses] = useState(null);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const { app } = useParams();

  const { user } = useAuth0();
  const userSalesforceId = getUserCustomProperty(user, "salesforce_Id");
  const getAccessTokenRequestHeaderConfig = useAccessTokenRequestHeaderConfig();

  useEffect(() => {
    const fetchCourses = async () => {
      const data = await makeFetchRequest(
        `/api/courses?userId=${userSalesforceId}&getImage=true`,
        await getAccessTokenRequestHeaderConfig()
      ).catch((err) => {
        console.error(err.stack);
        message.error("Error: " + err.message);
      });

      if (data) {
        if (data.length === 1) {
          navigate(
            `/course/${data[0].sfid}/${app}${
              app === "reporting"
                ? "?progress=enrolled&content=tutorials&reportType=Cohorts+with+Students+against+Tutorials"
                : ""
            }`,
            {
              replace: true,
            }
          );
        } else {
          setCourses(data);
          setLoading(false);
        }
      }
    };

    fetchCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CourseList = () => {
    if (!loading) {
      if (courses) {
        return courses.map((courseInfo) => (
          <CourseCard
            courseName={courseInfo.name}
            courseId={courseInfo.sfid}
            courseImage={courseInfo.image}
            cardLogo={courseInfo.logo}
            key={courseInfo.id}
          />
        ));
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Spin wrapperClassName={styles.spin} spinning={loading} size="large">
          <div className={styles.heading}>
            {!loading && courses.length > 1
              ? app === "reporting"
                ? "REPORTING"
                : "MANAGE COHORTS"
              : !loading && courses.length < 1
              ? "You do not have access to any courses"
              : null}
          </div>
          <div className={styles.cardList}>
            <CourseList className={styles.cardList} />
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default CoursesContainer;
