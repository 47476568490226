import React, { useState, useEffect, useRef } from "react";
import { message, Tooltip } from "antd";
import {
  makeFetchRequest,
  useAccessTokenRequestHeaderConfig,
} from "utils/requestUtils";
import {
  filterCohortAndStudents,
  searchInCohortsAndStudents,
  updateCohortHierarchyForUI,
} from "utils/hierarchyUtils";
import { createDownloadableDataFromHierarchy } from "utils/exportReportUtils";
import ReportTable, { TABLE_CELL } from "components/ReportTable/ReportTable";
import CohortNameCell from "components/ReportTable/CohortNameCell/CohortNameCell";
import { COHORT_TYPES } from "constants/index";
import styles from "./CohortWithStudentsAgainstTutorialContainer.module.css";

const CohortWithStudentsAgainstTutorialContainer = ({
  courseId,
  cohortId,
  cohortProgressFilter,
  searchText,
  userId,
  cohortsToFilter,
  studentsToFilter,
  tutorialsToFilter,
}) => {
  const [tutorials, setTutorials] = useState([]);
  const [unfilteredReportData, setUnfilteredReportData] = useState();
  const [reportData, setReportData] = useState([]);
  const [loadingTutorials, setLoadingTutorials] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const getAccessTokenRequestHeaderConfig = useAccessTokenRequestHeaderConfig();
  const filteredReportData = useRef(null);

  useEffect(() => {
    if (courseId) {
      fetchTutorials();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId]);

  useEffect(() => {
    if (cohortId) {
      fetchReportData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cohortId, cohortProgressFilter]);

  useEffect(() => {
    if (!unfilteredReportData) {
      setReportData([]);
      return;
    }
    if (!cohortsToFilter.length && !studentsToFilter.length) {
      setReportData(createTreeData(unfilteredReportData));
      filteredReportData.current = null;
    } else {
      const filteredData = updateCohortHierarchyForUI(
        filterCohortAndStudents(
          unfilteredReportData,
          cohortsToFilter,
          studentsToFilter
        )
      );
      filteredReportData.current = filteredData;
      setReportData(createTreeData(filteredData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cohortsToFilter, studentsToFilter]);

  useEffect(() => {
    setReportData(createTreeData(getReportDataToDisplay()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const getReportDataToDisplay = () => {
    const dataToSearchIn =
      filteredReportData && filteredReportData.current !== null
        ? filteredReportData.current
        : unfilteredReportData;
    if (!dataToSearchIn) {
      return null;
    }
    return !searchText
      ? dataToSearchIn
      : updateCohortHierarchyForUI(
          searchInCohortsAndStudents(dataToSearchIn, searchText.toLowerCase())
        );
  };

  const fetchTutorials = async () => {
    const fetchTutorialData = async () => {
      const data = await makeFetchRequest(
        `/api/tutorials?courseId=${courseId}&withExam=true&withSurvey=true`,
        await getAccessTokenRequestHeaderConfig()
      ).catch((err) => {
        console.error(err.stack);
        message.error("Error: " + err.message);
      });
      if (data) {
        setTutorials(data);
      }
    };

    setLoadingTutorials(true);
    await fetchTutorialData();
    setLoadingTutorials(false);
  };

  // Create Tree data
  const createTreeData = (cohortHierarchy) => {
    // Create cohort tree data for each top level cohort
    // parentHasChildCohorts is only required to adjust the padding so that if a student is present in a cohort but not any child, it is left aligned properly
    const createNodeElement = (node, parentHasChildCohorts) => {
      if (!node.isStudent) {
        parentHasChildCohorts = node.children?.find(
          (child) => !child.isStudent
        );
      }
      const nodeElement = {
        ...node,
        key: node.isStudent
          ? `${node.cohort_id}-${node.enrollment_id}`
          : node.id,
        name: (
          <CohortNameCell
            name={node.name ? node.name : node.enrollment_id}
            isStudent={!!node.isStudent}
            showBadge={node.type !== COHORT_TYPES.COURSE}
            badgeCount={node.studentCount}
            additionalClass={
              !!node.isStudent && parentHasChildCohorts
                ? styles.studentName
                : ""
            }
          />
        ),
        nameText: (node.name ? node.name : node.enrollment_id).toLowerCase(),
        isLeaf: node.children?.length > 0 ? false : true,
        children:
          node.children?.length > 0
            ? node.children.map((child) =>
                createNodeElement(child, parentHasChildCohorts)
              )
            : null,
      };
      return nodeElement;
    };

    const treeData = [];
    if (!!cohortHierarchy) {
      const topLevelCohorts = [cohortHierarchy];
      topLevelCohorts.forEach((node) => {
        const subtreeData = createNodeElement(node);
        treeData.push(subtreeData);
      });
    }
    return treeData;
  };

  const fetchReportData = async () => {
    const fetchData = async () => {
      const data = await makeFetchRequest(
        `/api/reporting/cohortWithStudentsAgainstTutorials?cohortId=${cohortId}&userId=${userId}&cohortProgress=${cohortProgressFilter}`,
        await getAccessTokenRequestHeaderConfig()
      ).catch((err) => {
        console.error(err.stack);
        message.error("Error: " + err.message);
      });

      if (data) {
        const cohortsWithChildrenToDisplay = updateCohortHierarchyForUI(data);
        setUnfilteredReportData(cohortsWithChildrenToDisplay);
        setReportData(createTreeData(cohortsWithChildrenToDisplay));
      }
    };

    setLoadingData(true);
    await fetchData();
    setLoadingData(false);
  };

  const columnItemWidth = 60;

  const getFilteredTutorials = () => {
    return tutorials.filter(
      (t) => !tutorialsToFilter.length || tutorialsToFilter.includes(t.sfid)
    );
  };

  const getTutorialColumnMap = () => {
    const tutorialColumns = getFilteredTutorials().map((tutorial, index) => ({
      title: <Tooltip title={tutorial.name}>{index + 1}</Tooltip>,
      dataIndex: tutorial.sfid,
      width: columnItemWidth,
      align: "center",
      onCell: (record) => {
        if (!record[tutorial.sfid]) {
          return {
            className: TABLE_CELL.NO_DATA,
          };
        }
        // index should use column index
        return index % 2 === 0 ? {} : { className: TABLE_CELL.BLUE };
      },
    }));
    if (tutorialColumns.length === 0) {
      tutorialColumns.push({
        title: "",
        width: 150,
      });
    }
    return tutorialColumns;
  };

  const columns = [
    {
      title: "",
      dataIndex: "",
      colSpan: 3,
      onHeaderCell: (column) => ({
        style: {
          backgroundColor: "#F0F0F0",
          border: "none",
        },
      }),
      children: [
        {
          title: "Cohort With Students",
          dataIndex: "name",
          fixed: "left",
          key: "key",
          onHeaderCell: (column) => ({
            style: {
              fontWeight: "700",
            },
          }),
        },
        {
          title: "Progress",
          dataIndex: "progressPercentageDisplay",
          fixed: "left",
          width: 150,
          align: "center",
          sorter: (a, b) => {
            return a.progressPercentage - b.progressPercentage;
          },
          onHeaderCell: (column) => ({
            style: {
              fontWeight: "700",
            },
          }),
        },
        {
          title: "Result",
          dataIndex: "result",
          fixed: "left",
          width: 150,
          align: "center",
          onHeaderCell: (column) => ({
            style: {
              fontWeight: "700",
            },
          }),
        },
      ],
    },
    {
      title: <div className={styles.grouped_column_header}>Tutorials</div>,
      dataIndex: "tutorials",
      align: "left",
      ellipsis: true,
      onHeaderCell: (column) => ({
        style: {
          backgroundColor: "#f0f0f0",
          padding: "0px",
        },
      }),
      children: getTutorialColumnMap(),
    },
  ];

  const getColumnsInDownloadedReport = () => {
    const columnsForDownload = [
      {
        title: "Student Name",
        dataIndex: "name",
      },
      {
        title: "Enrolment Id",
        dataIndex: "enrollment_id",
      },
      {
        title: "Progress",
        dataIndex: "progressPercentageDisplay",
      },
      {
        title: "Result",
        dataIndex: "result",
      },
    ];
    columnsForDownload.push(
      ...getFilteredTutorials().map((tutorial) => ({
        title: tutorial.name.replaceAll('"', '""'),
        dataIndex: tutorial.sfid,
      }))
    );
    return columnsForDownload;
  };

  const scrollWidth = 700 + columnItemWidth * columns[1].children.length;

  const downloadHandler = () =>
    createDownloadableDataFromHierarchy(
      getColumnsInDownloadedReport(),
      getReportDataToDisplay()
    );

  return (
    <ReportTable
      data={reportData}
      columns={columns}
      scroll={{
        x: scrollWidth,
        y: 500,
      }}
      loading={loadingData || loadingTutorials}
      downloadFileName="Cohort With Student Against Tutorials.csv"
      downloadHandler={downloadHandler}
    />
  );
};

export default CohortWithStudentsAgainstTutorialContainer;
