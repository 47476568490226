import React, { useState, useEffect, useRef } from "react";
import { message, Tooltip } from "antd";
import {
  makeFetchRequest,
  useAccessTokenRequestHeaderConfig,
} from "utils/requestUtils";
import ReportTable, {
  TABLE_ROW,
  TABLE_CELL,
} from "components/ReportTable/ReportTable";

import {
  filterCohortAndStudents,
  searchInCohortsAndStudents,
  updateCohortHierarchyForUI,
} from "utils/hierarchyUtils";
import { createDownloadableDataFromHierarchy } from "utils/exportReportUtils";
import CohortNameCell from "components/ReportTable/CohortNameCell/CohortNameCell";
import { COHORT_TYPES, QUESTION_TYPES } from "constants/index";
import styles from "./CohortWithStudentsAgainstQuestionsContainer.module.css";
import { FileDoneOutlined } from "@ant-design/icons";
import { contentBuilderURL } from "config/urlConfig";

const CONTENT_BUILDER_URL = contentBuilderURL[process.env.REACT_APP_ENV];

const SURVEY_CELL = [
  TABLE_CELL.CIRCLE_1,
  TABLE_CELL.CIRCLE_2,
  TABLE_CELL.CIRCLE_3,
  TABLE_CELL.CIRCLE_4,
  TABLE_CELL.CIRCLE_5,
];
const CohortWithStudentsAgainstQuestionContainer = ({
  cohortId,
  cohortProgressFilter,
  userId,
  searchText,
  cohortsToFilter,
  studentsToFilter,
  tutorialsToFilter,
  examsToFilter,
}) => {
  const parser = new DOMParser();
  const [questions, setQuestions] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [unfilteredReportData, setUnfilteredReportData] = useState();

  const getAccessTokenRequestHeaderConfig = useAccessTokenRequestHeaderConfig();
  const filteredReportData = useRef(null);

  useEffect(() => {
    if (cohortId) {
      fetchReportData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cohortId, cohortProgressFilter]);

  useEffect(() => {
    if (!unfilteredReportData) {
      setReportData([]);
      return;
    }
    if (!cohortsToFilter.length && !studentsToFilter.length) {
      setReportData(createTreeData(unfilteredReportData));
      filteredReportData.current = null;
    } else {
      const filteredData = updateCohortHierarchyForUI(
        filterCohortAndStudents(
          unfilteredReportData,
          cohortsToFilter,
          studentsToFilter
        )
      );
      filteredReportData.current = filteredData;
      setReportData(createTreeData(filteredData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cohortsToFilter, studentsToFilter]);

  useEffect(() => {
    setReportData(createTreeData(getReportDataToDisplay()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const getReportDataToDisplay = () => {
    const dataToSearchIn =
      filteredReportData && filteredReportData.current !== null
        ? filteredReportData.current
        : unfilteredReportData;
    if (!dataToSearchIn) {
      return null;
    }
    return !searchText
      ? dataToSearchIn
      : updateCohortHierarchyForUI(
          searchInCohortsAndStudents(dataToSearchIn, searchText.toLowerCase())
        );
  };

  // Create Tree data
  const createTreeData = (cohortHierarchy) => {
    // Create cohort tree data for each top level cohort
    // parentHasChildCohorts is only required to adjust the padding so that if a student is present in a cohort but not any child, it is left aligned properly
    const createNodeElement = (node, parentHasChildCohorts) => {
      if (!node.isStudent) {
        parentHasChildCohorts = node.children?.find(
          (child) => !child.isStudent
        );
      }
      const nodeElement = {
        ...node,
        key: node.isStudent
          ? `${node.cohort_id}-${node.enrollment_id}`
          : node.id,
        name: (
          <CohortNameCell
            name={node.name ? node.name : node.enrollment_id}
            isStudent={!!node.isStudent}
            showBadge={node.type !== COHORT_TYPES.COURSE}
            badgeCount={node.studentCount}
            additionalClass={
              !!node.isStudent && parentHasChildCohorts
                ? styles.studentName
                : ""
            }
          />
        ),
        isLeaf: node.children?.length > 0 ? false : true,
        children:
          node.children?.length > 0
            ? node.children.map((child) =>
                createNodeElement(child, parentHasChildCohorts)
              )
            : null,
      };
      return nodeElement;
    };

    const treeData = [];
    if (!!cohortHierarchy) {
      const topLevelCohorts = [cohortHierarchy];
      topLevelCohorts.forEach((node) => {
        const subtreeData = createNodeElement(node);
        treeData.push(subtreeData);
      });
    }
    return treeData;
  };

  const fetchReportData = async () => {
    const fetchData = async () => {
      const data = await makeFetchRequest(
        `/api/reporting/cohortWithStudentsAgainstQuestions?cohortId=${cohortId}&userId=${userId}&cohortProgress=${cohortProgressFilter}`,
        await getAccessTokenRequestHeaderConfig()
      ).catch((err) => {
        console.error(err.stack);
        message.error("Error: " + err.message);
      });

      if (data) {
        const cohortsWithChildrenToDisplay = updateCohortHierarchyForUI(
          data.cohortHierarchy
        );
        setUnfilteredReportData(cohortsWithChildrenToDisplay);
        setReportData(createTreeData(cohortsWithChildrenToDisplay));
        const questionsWithParsedText = data.questions?.map((q) => ({
          ...q,
          questionText: parser.parseFromString(q.question, "text/html")
            .documentElement.textContent,
          questionWithOptions: parser.parseFromString(
            q.questionWithOptions,
            "text/html"
          ).documentElement.textContent,
        }));
        setQuestions(questionsWithParsedText);
      }
    };

    setLoadingData(true);
    await fetchData();
    setLoadingData(false);
  };

  /* QUESTIONS */
  const questionCellDisplay = (text, record, question) => {
    if (!record.isStudent) {
      return !text ? (
        <div className={TABLE_CELL.NO_DATA}></div>
      ) : question.type === QUESTION_TYPES.SURVEY ||
        question.type === QUESTION_TYPES.BINARY_RESPONSE ? (
        <div className={TABLE_CELL.BLUE_RECTANGLE}>{text}</div>
      ) : (
        text
      );
    }
    // styling student answers
    let cellDisplay = <div className={TABLE_CELL.NO_DATA}></div>;
    if (text) {
      if (question.type === QUESTION_TYPES.MULTIPLE_CHOICE) {
        if (text === question.correctAnswerValue) {
          cellDisplay = <div>{text}</div>;
        } else {
          cellDisplay = <div className={TABLE_CELL.INCORRECT_RED}>{text}</div>;
        }
      } else if (question.type === QUESTION_TYPES.FREE_TEXT) {
        cellDisplay = (
          <div className={TABLE_CELL.TEXT_ICON}>
            <FileDoneOutlined />
          </div>
        );
      } else if (
        question.type === QUESTION_TYPES.SURVEY ||
        question.type === QUESTION_TYPES.BINARY_RESPONSE
      ) {
        cellDisplay = (
          <Tooltip
            title={
              parser.parseFromString(text.response, "text/html").documentElement
                .textContent
            }
          >
            <div className={SURVEY_CELL[text.responseIndex - 1]}>
              {text.responseIndex}
            </div>
          </Tooltip>
        );
      }
    }
    return cellDisplay;
  };

  const getFilteredQuestions = () => {
    return questions.filter(
      (q) =>
        (!tutorialsToFilter.length && !examsToFilter.length) ||
        tutorialsToFilter.includes(q.tutorialid) ||
        examsToFilter.includes(q.tutorialid)
    );
  };

  const columnItemWidth = 65;

  const getQuestionsColumns = () => {
    const questionColumns = getFilteredQuestions().map((question, index) => ({
      title: (
        <Tooltip
          overlayStyle={{ whiteSpace: "pre-line" }}
          title={question.questionWithOptions}
        >
          <a
            className={styles.question_column_number}
            href={`${CONTENT_BUILDER_URL}${question.link}`}
            target="_blank"
            rel="noreferrer"
          >
            {question.number}
          </a>
        </Tooltip>
      ),
      dataIndex: question.sfid,
      width: columnItemWidth,
      align: "center",
      onHeaderCell: (column) => ({
        style: {
          padding: "0.6rem 0",
        },
      }),
      render: (text, record) => questionCellDisplay(text, record, question),
    }));
    if (questionColumns.length === 0) {
      questionColumns.push({
        title: "",
        width: 150,
      });
    }
    return questionColumns;
  };

  const columns = [
    {
      title: "",
      dataIndex: "",
      colSpan: 3,
      onHeaderCell: (column) => ({
        style: {
          backgroundColor: "#F0F0F0",
          border: "none",
        },
      }),
      children: [
        {
          title: "Cohort With Students",
          dataIndex: "name",
          fixed: "left",
          key: "key",
          onHeaderCell: (column) => ({
            style: {
              fontWeight: "700",
            },
          }),
        },
        {
          title: "Progress",
          dataIndex: "progressPercentageDisplay",
          fixed: "left",
          width: 150,
          align: "center",
          sorter: (a, b) => {
            return a.progressPercentage - b.progressPercentage;
          },
          onHeaderCell: (column) => ({
            style: {
              fontWeight: "700",
            },
          }),
        },
        {
          title: "Result",
          dataIndex: "result",
          fixed: "left",
          width: 150,
          align: "center",
          onHeaderCell: (column) => ({
            style: {
              fontWeight: "700",
            },
          }),
        },
      ],
    },
    {
      title: <div className={styles.grouped_column_header}>Questions</div>,
      dataIndex: "questionText",
      align: "left",
      onHeaderCell: (column) => ({
        style: {
          backgroundColor: "#f0f0f0",
          padding: "0px",
        },
      }),
      children: getQuestionsColumns(),
    },
  ];

  const getColumnsInDownloadedReport = () => {
    const columnsForDownload = [
      {
        title: "Student Name",
        dataIndex: "name",
      },
      {
        title: "Enrolment Id",
        dataIndex: "enrollment_id",
      },
      {
        title: "Progress",
        dataIndex: "progressPercentageDisplay",
      },
      {
        title: "Result",
        dataIndex: "result",
      },
    ];
    columnsForDownload.push(
      ...getFilteredQuestions().map((question) => ({
        title: question.questionText.replaceAll('"', '""'),
        dataIndex: question.sfid,
        tutorial: question.tutorial,
        type: "text/html",
        format: (data) => (typeof data === "object" ? data.response : data),
      }))
    );
    return columnsForDownload;
  };

  const downloadHandler = () =>
    createDownloadableDataFromHierarchy(
      getColumnsInDownloadedReport(),
      getReportDataToDisplay(),
      "tutorial"
    );

  const scrollWidth = 700 + columnItemWidth * columns[1].children.length;

  return (
    <ReportTable
      rowClassName={(record, index) =>
        index % 2 === 0 ? TABLE_ROW.WHITE_BACKGROUND : TABLE_ROW.BLUE_BACKGROUND
      }
      data={reportData}
      columns={columns}
      scroll={{
        x: scrollWidth,
        y: 500,
      }}
      loading={loadingData}
      downloadFileName="Cohort With Student Against Questions.csv"
      downloadHandler={downloadHandler}
    />
  );
};

export default CohortWithStudentsAgainstQuestionContainer;
