import React from "react";
import styles from "./HomeContainer.module.css";
import HomePageTile from "./HomePageTile/HomePageTile";
import { AreaChartOutlined, ApartmentOutlined } from "@ant-design/icons";

const HomeContainer = () => {
  return (
    <div className={styles.container}>
      <HomePageTile
        heading="Reporting"
        link="/courses/reporting"
        tileImage={AreaChartOutlined}
      />
      <HomePageTile
        heading="Manage Cohorts"
        link="/courses/manage-cohorts"
        tileImage={ApartmentOutlined}
      />
    </div>
  );
};

export default HomeContainer;
